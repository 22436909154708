import {
	createApp
} from 'vue'
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router/index.js';

import mainRequest from './api/main/index.js';

import * as ElementPlusIconsVue from '@element-plus/icons-vue'


const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}
app.use(ElementPlus)
app.use(router);
app.provide('mainRequest', mainRequest);
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
app.use(VueVideoPlayer)
app.mount('#app')

/* 
new Vue({
  render: h => h(App),
}).$mount('#app') */