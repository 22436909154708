import mainRequest from '@/libs/request/mainRequest.js';

function loginAdmin(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/login/loginAdmin',
		data: data
	})
}

function loginDeviceAdmin(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/login/loginDeviceAdmin',
		data: data
	})
}

export default {
	loginAdmin,
	loginDeviceAdmin
}