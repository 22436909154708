<template>
	<div class="">
		<div class="mat-main w100 bsbb">
			<div class="wh100 mat-content">
				<div class="title-area w100 hc">
					<div style="flex:1">
					</div>
					<el-button type="primary" @click="clickAdd">新建</el-button>
				</div>
				<div class="table-area w100">
					<el-table v-loading="loading" :data="matList" style="height: 100%;" empty-text="无">
						<el-table-column type="selection" width="40" />
						<el-table-column type="index" label="序号" width="80" />
						<el-table-column prop="name" label="名称" min-width="150" />
						<el-table-column prop="idCard" label="号" min-width="240" />
						<el-table-column prop="idCard" label="视频" width="80">
							<template #default="scope">
								<div>
									<el-button type="primary" @click="downRow(scope)" link v-if="scope.row.videoUrl && scope.row.videoUrl.indexOf('http') != -1">下载</el-button>
									<div v-if="scope.row.videoUrl && scope.row.videoUrl.indexOf('http') == -1">加载中</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="idCard" label="原图" width="80">
							<template #default="scope">
								<el-image style="width: 50px;height: 80px;border-radius: 2px;" fit="cover" :src="scope.row.imageUrl"></el-image>
							</template>
						</el-table-column>
						<el-table-column prop="idCard" label="高清" width="80">
							<template #default="scope">
								<el-tag type="info" v-if="scope.row.highType == 0">
									否
								</el-tag>
								<el-tag type="primary" v-if="scope.row.highType == 1">
									是
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="180">
							<template #default="scope">
								<el-button link @click="editRow(scope)" type="primary">编辑</el-button>
								<el-button link @click="removeRow(scope)" type="primary">移除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="jc w100" style="height: 50px;">
					<el-pagination v-model:current-page="pageData.pageNum" v-model:page-size="pageData.pageSize" :page-sizes="[20,50,100,200]" :small="false" :background="false" layout="sizes, prev, pager, next, jumper" :total="pageTotal" @size-change="handleSizeChange"
						@current-change="handleCurrentChange" />
				</div>
			</div>
		</div>

		<el-dialog align-center v-model="dialogVisible" :title="'编辑'" width="500px">
			<div class="form-area w100 bsbb" style="padding: 0 20px;">
				<el-form class="el-form w100" style="" require-asterisk-position="left" ref="formRef" :model="formData">
					<el-form-item prop="rootId" :label-width="'100px'" class="user-form-item" label="rootId">
						<el-input maxlength="10" v-model="formData.rootId" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="nickname" :label-width="'100px'" class="user-form-item" label="名称">
						<el-input maxlength="20" v-model="formData.name" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="mobile" :label-width="'100px'" class="user-form-item" label="号码">
						<el-input maxlength="30" v-model="formData.idCard" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="mobile" :label-width="'100px'" class="user-form-item" label="视频">
						<el-upload :data="uploaderData" accept=".mp4,.mov" ref="uploaderVideo" class="upload-demo w100" action="https://file.douyin.bet/api/materials/upload" :on-success="onSuccess" :limit="1" :on-exceed="handleExceed" :auto-upload="true">
							<template #trigger>
								<el-button type="primary">选择视频</el-button>
							</template>
						</el-upload>
						<div v-if="formData.videoUrl" class="wh100 jc fl" style="height: 200px;">
							<video-player v-if="formData.videoUrl" class="bd fl" style="width: 330px;height: 200px;border-radius: 20px;" :src="formData.videoUrl" :controls="true" />
						</div>
					</el-form-item>
					<el-form-item prop="mobile" :label-width="'100px'" class="user-form-item" label="原图">
						<el-upload :data="uploaderData" accept=".jpg,.png,.jpeg" ref="uploaderImage" class="upload-demo fl w100" action="https://file.douyin.bet/api/materials/upload" :on-success="onSuccessImage" :limit="1" :on-exceed="handleExceedImage" :auto-upload="true">
							<template #trigger>
								<el-button type="primary">选择图片</el-button>
							</template>
						</el-upload>
						<div v-if="formData.imageUrl" class="wh100 jc fl" style="height: 80px;">
							<el-image fit="cover" style="width: 80px;height: 80px;border-radius: 10px;" :src="formData.imageUrl"></el-image>
						</div>
					</el-form-item>
					<el-form-item prop="email" :label-width="'100px'" class="user-form-item" label="高清">
						<el-select v-model="formData.highType">
							<el-option label="是" :value="1"></el-option>
							<el-option label="否" :value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="infoDiaVis = false">取消</el-button>
					<el-button type="primary" @click="submitInfoForm">确认</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
	import {
		ref,
		inject,
		onMounted
	} from 'vue';

	import localCache from '../../libs/utils/localCache.js';

	const mainApi = inject('mainRequest');

	const uploaderData = ref({
		token: ''
	})

	const dialogVisible = ref(false);

	const loading = ref(false);

	const formData = ref({
		"rootId": "",
		"name": "",
		"idCard": "",
		"videoUrl": "",
		"imageUrl": "",
		"highType": 0
	})

	const resetFormData = () => {
		formData.value = {
			"rootId": "",
			"name": "",
			"idCard": "",
			"videoUrl": "",
			"imageUrl": "",
			"highType": 0
		};
	}
	
	const downRow = (scope) => {
		downloadFile(scope.row.videoUrl,scope.row.name + scope.row.idCard)
	}

	function downloadFile(filePath, name) {
		const link = document.createElement('a')
		link.style.display = 'none'
		link.href = filePath
		link.setAttribute('download', name);
		link.download = name
		document.body.appendChild(link)
		link.click()
	}

	const pageTotal = ref(0)

	const uploaderVideo = ref(null);

	const uploaderImage = ref(null);

	const onSuccess = (res) => {
		if (res.success) {
			formData.value.videoUrl = 'https://file.douyin.bet/api/materials/download/' + res.data;
		} else {
			ElMessage({
				message: '上传失败',
				type: 'error'
			})
		}
	}

	const onSuccessImage = (res) => {
		if (res.success) {
			formData.value.imageUrl = 'https://file.douyin.bet/api/materials/download/' + res.data;
		} else {
			ElMessage({
				message: '上传失败',
				type: 'error'
			})
		}
	}

	const handleExceed = (files) => {
		uploaderVideo.value.clearFiles();
		const file = files[0];
		file.uid = genFileId();
		uploaderVideo.value.handleStart(file);
	}

	const handleExceedImage = (files) => {
		uploaderImage.value.clearFiles();
		const file = files[0];
		file.uid = genFileId();
		uploaderImage.value.handleStart(file);
	}

	const clickAdd = () => {
		resetFormData();
		dialogVisible.value = true;
	}

	const submitInfoForm = () => {
		if (!formData.value.rootId) {
			ElMessage({
				message: '请输入rootId'
			})
			return;
		}

		if (!formData.value.name) {
			ElMessage({
				message: '请输入名称'
			})
			return;
		}

		if (!formData.value.idCard) {
			ElMessage({
				message: '请输入号码'
			})
			return;
		}

		if (!formData.value.imageUrl) {
			ElMessage({
				message: '请选择图片'
			})
			return;
		}

		let methodName = 'insertMaterial';

		if (formData.value.materialId) {
			methodName = 'editMaterial';
		}

		mainApi.admin[methodName]({
			...formData.value
		}).then(res => {
			/* console.log(res); */
			getMatPage();
			if (res.flag) {
				dialogVisible.value = false;
			} else {
				ElMessage({
					message: res.errorMsg
				})
			}
		})
	}

	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus';

	const handleCurrentChange = (e) => {
		pageData.pageNum = e;
		getMatPage();
	}

	const handleSizeChange = (e) => {
		pageData.pageNum = 1;
		pageData.pageSize = e;
		getMatPage();
	}

	const pageData = ref({
		"checkNumMax": '', //通过次数max
		"sortType": 0,
		"pageNum": 1,
		"pageSize": 20
	})

	onMounted(() => {
		getMatPage();
		uploaderData.value.token = localCache.getCache('ad-cache');
	})

	const matList = ref([]);

	const getMatPage = () => {
		loading.value = true;
		mainApi.admin.getMaterialPage({
			...pageData.value
		}).then(res => {
			console.log(res);
			loading.value = false;
			if (res.flag) {
				matList.value = res.data.pageInfo.list;
				pageTotal.value = res.data.pageInfo.total;
			} else {
				ElMessage({
					message: res.errorMsg,
					type: 'error'
				})
			}
		})
	}

	const editRow = (scope) => {

		formData.value = {
			...scope.row
		};
		dialogVisible.value = true;
	}

	const removeRow = (scope) => {
		console.log(scope.row.materialId);
		ElMessageBox.confirm('是否删除?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			console.log(1);
			mainApi.admin.delMaterial({
				ids: [scope.row.materialId]
			}).then(res => {
				if (res.flag) {
					ElMessage({
						message: '已删除',
						type: 'success'
					})
				} else {
					ElMessage({
						message: res.errorMsg,
						type: 'error'
					})
				}
				getMatPage()
			})
		}).catch(() => {});
	}
</script>

<style lang="scss">
	.mat-main {
		height: 100vh;
		padding: 30px 35px;
	}

	.mat-content {
		display: flex;
		flex-direction: column;

		.title-area {
			padding: 20px 0;
		}

		.table-area {
			flex: 1;
			height: 0;
		}
	}
</style>