import mainRequest from '@/libs/request/mainRequest.js';

function getRootMap(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/getRootMap',
		data: data
	})
}

function getAdminMap(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/getAdminMap',
		data: data
	})
}

function getMaterialPage(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/getMaterialPage',
		data: data
	})
}

function insertMaterial(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/insertMaterial',
		data: data
	})
}

function editMaterial(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/editMaterial',
		data: data
	})
}

function delMaterial(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/admin/delMaterial',
		data: data
	})
}
export default {
	getRootMap,
	getAdminMap,
	getMaterialPage,
	insertMaterial,
	editMaterial,
	delMaterial
}