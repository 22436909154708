import {
	createRouter,
	createWebHashHistory
} from 'vue-router';

import loginVue from '../views/login/login.vue';
import loginAdminVue from '../views/loginAdmin/login.vue';
import indexVue from '../views/index/index.vue';
import matVue from '../views/mat/mat.vue';
import matMngVue from '../views/matMng/matMng.vue';
import equipMngVue from '../views/equipMng/equipMng.vue';

const routes = [{
	path: '/',
	name: 'login',
	component: loginVue
}, {
	path: '/loginAdmin910911',
	name: 'loginAdmin',
	component: loginAdminVue
}, {
	path: '/mat910911',
	name: 'mat',
	component: matVue
}, {
	path: '/index',
	name: 'index',
	component: indexVue,
	children: [{
		path: '/index/mat',
		name: 'matIndex',
		component: matMngVue
	},{
		path: '/index/equip',
		name: 'equipIndex',
		component: equipMngVue
	}]
}]

const router = createRouter({
	// 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
	history: createWebHashHistory(),
	routes, // `routes: routes` 的缩写
})

export default router