<template>
	<div>
		<div class="login-main xct">
			<div class="login-card h100 hc bsbb">
				<div class="w100 login-inner">
					<div class="fl w100 login-tabs">
						<div class="fl w100" style="margin-top: 0px;">
							<div class="fl w100 form-lable">账号</div>
							<el-input maxlength="32" class="el-input" v-model="loginForm.account" size="large" placeholder="请输入账号" :prefix-icon="'User'" />
							<div class="fl w100 form-lable">密码</div>
							<el-input maxlength="32" @keyup.enter="loginNext" type="password" show-password class="el-input" v-model="loginForm.passWord" size="large" placeholder="请输入密码" :prefix-icon="'Lock'" />
							<el-button @click="loginNext" class="w100 el-button" type="primary" :loading="btnLoading">登录</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		ref,
		inject
	} from 'vue';
	import localCache from '@/libs/utils/localCache';

	const mainApi = inject('mainRequest');

	const btnLoading = ref(false);
	
	import {
		useRouter
	} from 'vue-router';
	
	const router = useRouter();

	import {
		ElMessage
	} from 'element-plus';

	const loginForm = ref({
		account: '',
		passWord: ''
	})

	const loginNext = () => {
		if (!loginForm.value.account) {
			ElMessage({
				message: '请输入账号',
				type: 'error'
			})
			return
		}

		if (!loginForm.value.passWord) {
			ElMessage({
				message: '请输入密码',
				type: 'error'
			})
			return
		}

		mainApi.login.loginAdmin({
			...loginForm.value
		}).then(res => {
			console.log(res);
			if (res.flag) {
				localCache.setCache('ad-cache', res.data);
				router.push('/mat910911')
			} else {
				if (res.errorMsg) {
					ElMessage({
						message: res.errorMsg,
						type: 'error'
					})
				}
			}
		})

	}
</script>

<style>
	.login-main {
		width: 360px;
	}

	.login-card {
		width: 360px;
		border-radius: 20px;
		padding: 30px 40px;
	}

	.login-inner {
		position: relative;
	}

	.bottom-tips {
		position: absolute;
		bottom: 20px;
		font-size: 14px;
	}

	.login-title {
		margin-top: 0px;
		font-weight: 600;
		text-align: left;
		font-size: 20px;
	}

	.login-tabs {
		margin-top: 15px;

		.form-lable {
			font-size: 13px;
			margin-top: 10px;
			color: #999;
			text-align: left;
		}

		.el-input {
			margin-top: 5px;
		}

		.el-input-rg {
			margin-top: 15px;
		}

		.el-button {
			margin-top: 45px;
			height: 35px;
		}

		.login-bottom {
			margin-top: 20px;
			font-size: 14px;

			.login-bottom-item {
				height: 25px;
			}

			.serve-text {
				font-size: 11px;
				color: #000;
			}

			.agree-text {
				color: var(--el-color-primary);
				margin: 0 5px;
			}
		}

		.el-tabs__header {
			margin: 0 !important;
		}

		:deep .el-tabs__item {
			font-size: 13px !important;
			/* border-bottom: solid 1px #EEE; */
			font-weight: 600;
			color: #888;
		}

		:deep .is-active {
			color: var(--el-color-primary) !important;
		}

		:deep .el-tabs__nav-wrap::after {
			/* 去掉下划线 */
			position: static !important;
		}

		:deep .el-tabs__item:hover {
			color: var(--el-color-primary);
			cursor: pointer;
		}

		.el-tabs__active-bar {
			display: none;
		}
	}

	.dialog-header {
		height: 80px;

		.header-title {

			.title-fix {
				color: #666;
				width: 50px;

				.icon-text {
					font-size: 12px;
					margin-left: 5px;
				}
			}

			.title-text {
				flex: 1;
				font-size: 16px;
				font-weight: 600;
				color: #000;
			}
		}

		.tips-area {
			margin-top: 10px;

			.tips-text {
				font-size: 13px;
				color: #999;
				padding: 1px 0;
			}
		}
	}

	.dialog-inner {
		height: 300px;
		background: #FFF;

		.el-row {
			margin-bottom: 20px;
		}

		.el-row:last-child {
			margin-bottom: 20px;
		}

		.el-col {
			border-radius: 4px;
		}

		.grid-content {
			border-radius: 4px;
			height: 70px;
			background: #F2F2F6;
			padding: 5px 10px;
			margin-bottom: 10px;

			.grid-text {
				flex: 1;
				padding: 0 10px;
				text-align: left;
				font-size: 13px;
			}
		}
	}

	.right-icon {
		color: #999;
		font-size: 18px;
		width: 45px;
		height: 35px;
		border-radius: 5px;
		cursor: pointer;
	}

	.right-icon:hover {
		background-color: #EEE;
	}
</style>