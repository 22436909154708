import mainRequest from '@/libs/request/mainRequest.js';

function getRootMap(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/getRootMap',
		data: data
	})
}

function getAdminMap(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/getAdminMap',
		data: data
	})
}

function getMaterialPage(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/getMaterialPage',
		data: data
	})
}

function insertMaterial(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/insertMaterial',
		data: data
	})
}

function editMaterial(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/editMaterial',
		data: data
	})
}

function delMaterial(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/delMaterial',
		data: data
	})
}

function getLoginDevice(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/getLoginDevice',
		data: data
	})
}

function editPassWord(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/editPassWord',
		data: data
	})
}

function editSecret(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/editSecret',
		data: data
	})
}

function editDevice(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/editDevice',
		data: data
	})
}

function delDevice(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/delDevice',
		data: data
	})
}


function getMaterialsLog(data) {
	return mainRequest({
		method: 'post',
		url: '/rich/server/v1/device/getMaterialsLog',
		data: data
	})
}

export default {
	getRootMap,
	getAdminMap,
	getMaterialPage,
	insertMaterial,
	editMaterial,
	delMaterial,
	getLoginDevice,
	editPassWord,
	editSecret,
	editDevice,
	delDevice,
	getMaterialsLog
}