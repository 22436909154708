import axios from 'axios';

import localCache from '../utils/localCache';

/* import Hawk from 'hawk';
let base = require('js-base64'); */

/* function getFail(credentials) {
	let auth = credentials.auth;
	delete credentials.auth;
	credentials.key = base.decode(auth);
	return credentials;
} 

function getCreat(acde) {
	let aoiie = {
		...acde
	};
	delete aoiie.tast;
	return {
		credential: aoiie,
		taks: acde.tast
	}
}*/

import {
	ElMessage,
	ElMessageBox
} from 'element-plus';

const mainRequest = axios.create({
	// 这里可以放一下公用属性等。
	baseURL: 'https://gateway.douyin.bet', // 用于配置请求接口公用部分，请求时会自动拼接在你定义的url前面。
	withCredentials: false, // 跨域请求时是否需要访问凭证
	timeout: 120 * 1000, // 请求超时时间
})

import router from '@/router';

// 请求拦截器
mainRequest.interceptors.request.use((config) => {
	let userBase = null;
	if (localCache.getCache('ad-cache')) {
		try {
			userBase = localCache.getCache('ad-cache');
		} catch (e) {
			//TODO handle the exception
		}
	}

	if (userBase && userBase.adminToken) {
		config.headers['adminToken'] = userBase.adminToken;
	}
	
	if (userBase && userBase.deviceAdminToken) {
		config.headers['deviceAdminToken'] = userBase.deviceAdminToken;
	}
	
	/* if (getToken('satoken')) {
		config.headers['satoken'] = getToken('satoken'); //携带token
		config.headers['Content-type'] = 'application/json';
	} */

	/* let CreateCredentials = Hawk.client.CreateCredentials;
	var credentials = CreateCredentials('avoStgWa',
		'YTc0NWYzYXNkZGRkZGRkYXNkM2Q4NmNiODc=',
		'sha256');
	credentials = getFail(credentials);

	const {
		credential,
		taks
	} = getCreat(credentials);

	let crHead = Hawk.client.header;
	let headData = crHead(config.baseURL + config.url, config.method, {
		credentials: credential,
		ext: '',
		nonce: 'lvtrx6'
	});

	config.headers['Authorization'] = headData.header;
	config.headers['X-FROM-HOST'] = config.baseURL + config.url; */
	return config;
}, (error) => {
	return Promise.reject(error)
})

mainRequest.interceptors.response.use((response) => {
	//返回码以自己的为定，如果没有安装elementui就换成自己的提示
	/* let {
		code,
		msg
	} = response.data
	if (code != 200) {
		Message({
			message: msg || 'error',
			type: 'warning'
		})
	} */

	if (response.data && response.data.errorMsg == '登录已过期，请重新登录') {
		if (ElMessage) {
			ElMessage({
				showClose: true,
				message: '登录已过期，请重新登录',
				type: 'error',
			})
			localCache.clearCache();
			router.push('/');
		}
	}
	return response.data;
}, (error) => {
	return {
		statusCode: 404,
		message: error.message
	}
})

export default mainRequest; //记得暴露出去