<template>
	<div class="">
		<div class="mat-main w100 bsbb">
			<div class="wh100 mat-content">
				<div class="title-area w100 hc">
					<el-button type="primary" @click="clickChangeRootPass">修改机构密码</el-button>
					<el-button type="primary" @click="clickChangeSecret">修改密钥</el-button>
					<div style="flex:1">
					</div>
				</div>
				<div class="table-area w100" style="position: relative;">
					<el-table v-loading="loading" :data="matList" style="height: 100%;width: 100%;position: absolute;top: 0;left: 0" empty-text="无">
						<el-table-column type="selection" width="40" />
						<el-table-column type="index" label="序号" width="80" />
						<el-table-column prop="deviceTitle" label="设备名称" width="80" />
						<el-table-column prop="deviceModel" label="机型" min-width="150" />
						<el-table-column prop="androidId" label="安卓ID" width="150" />
						<el-table-column show-overflow-tooltip="" prop="guid" label="GUID" width="150" />
						<el-table-column show-overflow-tooltip="" prop="serialNumber" label="序列号" width="150" />
						<el-table-column prop="idCard" label="状态" width="80">
							<template #default="scope">
								<el-tag type="info" v-if="scope.row.registerStatus == 0">
									否
								</el-tag>
								<el-tag type="primary" v-if="scope.row.registerStatus == 1">
									是
								</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="registerTime" label="注册时间" width="180" />
						<el-table-column prop="lastLoginTime" label="最近登录" width="180" />
						<el-table-column prop="lastUseTime" label="最近使用" width="180" />
						<el-table-column fixed="right" label="操作" width="180">
							<template #default="scope">
								<el-button link @click="editRow(scope)" type="primary">编辑</el-button>
								<el-button link @click="removeRow(scope)" type="primary">移除</el-button>
								<el-button link @click="recordRow(scope)" type="primary">记录</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="jc w100" style="height: 50px;">
					<el-pagination v-model:current-page="pageData.pageNum" v-model:page-size="pageData.pageSize" :page-sizes="[20,50,100,200]" :small="false" :background="false" layout="sizes, prev, pager, next, jumper" :total="pageTotal" @size-change="handleSizeChange"
						@current-change="handleCurrentChange" />
				</div>
			</div>
		</div>

		<el-dialog align-center v-model="dialogVisible" :title="'编辑'" width="500px">
			<div class="form-area w100 bsbb" style="padding: 0 20px;">
				<el-form class="el-form w100" style="" require-asterisk-position="left" ref="formRef" :model="formData">
					<el-form-item prop="nickname" :label-width="'100px'" class="user-form-item" label="设备名">
						<el-input maxlength="50" v-model="formData.deviceTitle" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="mobile" :label-width="'100px'" class="user-form-item" label="描述">
						<el-input maxlength="50" v-model="formData.description" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="mobile" :label-width="'100px'" class="user-form-item" label="设备密码">
						<el-input maxlength="50" v-model="formData.safePassWord" autocomplete="off" />
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="submitInfoForm">确认</el-button>
				</div>
			</template>
		</el-dialog>


		<el-dialog align-center v-model="dialogPass" :title="'修改密码'" width="500px">
			<div class="form-area w100 bsbb" style="padding: 0 20px;">
				<el-form class="el-form w100" style="" require-asterisk-position="left" ref="formRef" :model="formData">
					<el-form-item prop="rootId" :label-width="'100px'" class="user-form-item" label="旧密码">
						<el-input maxlength="10" v-model="formDataPass.oldPassWord" autocomplete="off" />
					</el-form-item>
					<el-form-item prop="nickname" :label-width="'100px'" class="user-form-item" label="新密码">
						<el-input maxlength="10" v-model="formDataPass.newPassWord" autocomplete="off" />
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogPass = false">取消</el-button>
					<el-button type="primary" @click="submitPassForm">确认</el-button>
				</div>
			</template>
		</el-dialog>


		<el-dialog align-center v-model="recordDialog" :title="'使用记录'" width="500px">
			<div class="form-area w100 bsbb" style="padding: 0 20px;">
				<el-table v-loading="loadingRecord" :data="recordList" style="height: 60vh;width: 100%;" empty-text="无">
					<el-table-column type="selection" width="40" />
					<el-table-column type="index" label="序号" width="80" />
					<el-table-column prop="idCard" label="时间" width="180">
						<template #default="scope">
							{{getTimeShow(scope.row.createTime)}}
						</template>
					</el-table-column>
					<el-table-column prop="idCard" label="是否通过" width="180">
						<template #default="scope">
							<el-tag v-if="scope.row.checkType == 1">是</el-tag>
							<el-tag type="info" v-else="">否</el-tag>
						</template>
					</el-table-column>
				</el-table>

				<div class="jc w100" style="height: 50px;">
					<el-pagination v-model:current-page="pageData.pageNum" v-model:page-size="pageData.pageSize" :page-sizes="[20,50,100,200]" :small="false" :background="false" layout="sizes, prev, pager, next, jumper" :total="pageTotal" @size-change="handleSizeChangeRecord"
						@current-change="handleCurrentChangeRecord" />
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="dialogPass = false">取消</el-button>
					<el-button type="primary" @click="submitPassForm">确认</el-button>
				</div>
			</template>
		</el-dialog>

	</div>
</template>

<script setup>
	import {
		ref,
		inject,
		onMounted
	} from 'vue';

	import localCache from '../../libs/utils/localCache.js';

	const mainApi = inject('mainRequest');

	const loadingRecord = ref(false);

	const uploaderData = ref({
		token: ''
	})

	const dialogVisible = ref(false);

	const dialogPass = ref(false);

	const recordDialog = ref(false);

	const loading = ref(false);

	const recordList = ref([])

	const formData = ref({
		"name": "",
		"idCard": "",
		"videoUrl": "",
		"imageUrl": "",
		"highType": 0
	})

	const formDataPass = ref({
		oldPassWord: '',
		newPassWord: ''
	})

	function convertToYMDHMS(dateStr) {
		const date = new Date(dateStr);
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份是从0开始的
		const day = ('0' + date.getDate()).slice(-2);
		const hours = ('0' + date.getHours()).slice(-2);
		const minutes = ('0' + date.getMinutes()).slice(-2);
		const seconds = ('0' + date.getSeconds()).slice(-2);

		return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	}

	const getTimeShow = (time) => {
		return convertToYMDHMS(time);
	}

	const submitPassForm = () => {
		if (!formDataPass.value.oldPassWord) {
			ElMessage({
				message: '输入旧密码',
				type: 'error'
			})
			return;
		}

		if (!formDataPass.value.newPassWord) {
			ElMessage({
				message: '输入新密码',
				type: 'error'
			})
			return;
		}

		mainApi.device.editPassWord({
			...formDataPass.value
		}).then(res => {
			if (res.flag) {
				ElMessage({
					message: '修改成功',
					type: 'success'
				})
				dialogPass.value = false;
			} else {
				ElMessage({
					message: res.errorMsg,
					type: 'error'
				})
			}
		})
	}

	const clickChangeSecret = () => {
		ElMessageBox.prompt('请输入新的密钥', '提示', {
				confirmButtonText: '确认',
				cancelButtonText: '取消'
			})
		.then(({
			value
		}) => {
			if (value) {
				mainApi.device.editSecret({
					secret: value
				}).then(res => {
					if (res.flag) {
						ElMessage({
							message: '修改成功',
							type: 'success'
						})
					} else {
						ElMessage({
							message: res.errorMsg,
							type: 'error'
						})
					}
				})
			} else {
				ElMessage({
					message: '请输入新的密钥',
					type: 'error'
				})
			}
		})
		.catch(() => {

		})
	}

	const clickChangeRootPass = () => {
		dialogPass.value = true;
	}

	const resetFormData = () => {
		formData.value = {
			"name": "",
			"idCard": "",
			"videoUrl": "",
			"imageUrl": "",
			"highType": 0
		};
	}

	const pageTotal = ref(0)

	const uploaderVideo = ref(null);

	const uploaderImage = ref(null);

	const onSuccess = (res) => {
		if (res.success) {
			formData.value.videoUrl = 'https://file.douyin.bet/api/materials/download/' + res.data;
		} else {
			ElMessage({
				message: '上传失败',
				type: 'error'
			})
		}
	}

	const onSuccessImage = (res) => {
		if (res.success) {
			formData.value.imageUrl = 'https://file.douyin.bet/api/materials/download/' + res.data;
		} else {
			ElMessage({
				message: '上传失败',
				type: 'error'
			})
		}
	}

	const handleExceed = (files) => {
		uploaderVideo.value.clearFiles();
		const file = files[0];
		file.uid = genFileId();
		uploaderVideo.value.handleStart(file);
	}

	const handleExceedImage = (files) => {
		uploaderImage.value.clearFiles();
		const file = files[0];
		file.uid = genFileId();
		uploaderImage.value.handleStart(file);
	}

	const recordData = ref({
		"deviceId": "",
		"pageNum": 1,
		"pageSize": 20
	})

	const recordRow = (e) => {
		console.log(e);
		recordData.value.pageNum = 1;
		recordData.value.deviceId = e.row.deviceId;
		getRecord();
		recordDialog.value = true;
	}
	const getRecord = () => {
		mainApi.device.getMaterialsLog({
			...recordData.value
		}).then(res => {
			console.log(res);
			if (res.flag) {
				recordList.value = res.data.pageInfo.list;
			} else {
				ElMessage({
					message: res.errorMsg,
					type: 'error'
				})
			}
		})
	}

	const clickAdd = () => {
		resetFormData();
		dialogVisible.value = true;
	}

	const submitInfoForm = () => {
		mainApi.device.editDevice({
			...formData.value
		}).then(res => {
			getEquipPage();
			if (res.flag) {
				dialogVisible.value = false;
			} else {
				ElMessage({
					message: res.errorMsg
				})
			}
		})
	}

	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus';

	const handleCurrentChange = (e) => {
		pageData.value.pageNum = e;
		getEquipPage();
	}

	const handleSizeChange = (e) => {
		pageData.value.pageSize = e;
		pageData.value.pageNum = 1;
		getEquipPage();
	}

	const handleCurrentChangeRecord = (e) => {
		recordData.value.pageNum = e;
		getRecord();
	}

	const handleSizeChangeRecord = (e) => {
		recordData.value.pageSize = e;
		recordData.value.pageNum = 1;
		getRecord();
	}

	const pageData = ref({
		"pageNum": 1,
		"pageSize": 30
	})

	onMounted(() => {
		getEquipPage();
		uploaderData.value.token = localCache.getCache('ad-cache');
	})

	const matList = ref([]);

	const getEquipPage = () => {
		loading.value = true;
		mainApi.device.getLoginDevice({
			...pageData.value
		}).then(res => {
			console.log(res);
			loading.value = false;
			if (res.flag) {
				matList.value = res.data.pageInfo.list;
				pageTotal.value = res.data.pageInfo.total;
			} else {

			}
		})
	}

	const editRow = (scope) => {

		formData.value = {
			...scope.row
		};
		dialogVisible.value = true;
	}

	const removeRow = (scope) => {
		ElMessageBox.confirm('是否删除?', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
		}).then(() => {
			console.log(1);
			mainApi.device.delDevice({
				deviceIds: [scope.row.deviceId]
			}).then(res => {
				if (res.flag) {
					ElMessage({
						message: '已删除',
						type: 'success'
					})
				} else {
					ElMessage({
						message: res.errorMsg,
						type: 'error'
					})
				}
				getEquipPage()
			})
		}).catch(() => {});
	}
</script>

<style lang="scss">
	.mat-main {
		height: 100vh;
		padding: 30px 35px;
	}

	.mat-content {
		display: flex;
		flex-direction: column;

		.title-area {
			padding: 20px 0;
		}

		.table-area {
			flex: 1;
			height: 0;
		}
	}
</style>